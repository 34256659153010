// project: g17182-horse-sema-gii9
// new measurement id: G-KPTSZDTCP0
// secret: BmtSbMTBTri0LpKbyy5QEw
// ga: 465549664

import generateUniqueSessionId from '../lib/generateUniqueSessionId.js'

const buttonBackground = "#000000";
const buttonText = "#ffffff";
const mainUrl = 'https://www.aurobay.com/exhibitions/sema-2024/';
const slug = "8112d3a9c24fe04d5f93f3f3def8e154|3b0467a1bb8e7db922f62d06ae618db734081ad51a04794a8410e81ada9a06095517b6c9ec4cf88d18a4565f521721c475f3a8b9451d9318194e";
const buttons = [
    {title: "Powertrain Specs"},
    {title: "Pikes Peak Winners!"},
    {title: "Who we are"},
    {title: "Get a quote"},
]
//.sort(() => Math.random() - 0.5);
const imageFolderName = "17182-aurobay-horse-sema";

const checkValue = (value, defaultValue) => {
  return (typeof(value) !== 'undefined') ? value : defaultValue
}
const darkMode = false
const styleParams = {
  mainColor: buttonBackground,
  secondaryColor: buttonText,
}
const style =           {
  // basic colors
  brandColor: checkValue(styleParams.brandColor, '#ffffff'),
  secondaryBrandColor: checkValue(styleParams.mainColor, 'lightblue'),
  chatBackgroundColor: (darkMode) ? '#000000' : '#ffffff', // the background color in the actual chat
  // cards
  cardBackground: (darkMode) ? '#333333' : '#ebe8e8', // the card background
  cardTextColor: checkValue(styleParams.cardTextColor, (darkMode) ?  '#ffffff' : '#000000'),// cards
  borderRadius: '8px', // cards + buttons
  imagesHaveBorders: true,
  // Users
  userTextColor: '#ffffff',// user options
  userBackgroundColor: "#959595",
  // Text Input
  UIButtonBackgroundColor: '#959595', // textInput send` button
  UIButtonStrokeColor: 'white', // textInput send button
  textInputColor: '#666666', // textInput options
  textInputBorderRadius: '16px', // textInput options
  typingIndicator: (darkMode) ? 'rgb(200,200,200)': 'rgb(55, 55, 55)',
}

const config = {
  darkMode: false,
  url: 'https://adchat-ai.uc.r.appspot.com/b',
  productionMode: true,
  headerLogoURL: mainUrl,
  headerButtonPostback: false,
  language: 'EN',
  logoURL: `images/${imageFolderName}/logo-left.png`,
  rightLogoURL: `images/${imageFolderName}/logo-right.png`,
  roundLogo: false,
  headerBackgroundPosition: '16px 50%',
  headerBackgroundColor: 'white',
  introPostback: buttons[0].title,
  firstFrameClickShouldChat: true,
  chatHeightOffset: 80,
  slugMode: true,
  slug: slug,
  firstFrameExitMode: true,
  firstFrameExitURL: mainUrl,
  chatTimingMS: 600,
  imagesHaveBorders: true,
  ySpaceBetweenCards: '6px',
  sessionId: generateUniqueSessionId(),
  platform: 'landing-page-en',
  hasInput: false,
  conversationHasInput: false,
  version: '1.0',
  headerExitURL: mainUrl,
  inputConfig: {
    textInputWidth: '80%',
    promptCopy: "Ask me about..."
  },
  firstFrame: {
    inputConfig: {
      textInputWidth: '80%',
      promptCopy: "Ask me about..."
    },
  },
  pixels: {},
  exits: {
  },
  exitRedirects: {
  },
  simulatedConversation: [
      {
          type: 'image',
          src: `./images/${imageFolderName}/first-frame.mp4`,
      },
      {
          type: 'brandResponse',
          text: "See us at **SEMA**. Central Hall, Racing & Performance, Booth 20031",
      },
      {
          type: 'quickReplies',
          text: "What are you looking for? 👇",
          size: 'large',
          buttons: buttons
      }
  ],
} 
import TemplateSocialView from '../views/TemplateSocialView.svelte';
const app = new TemplateSocialView({
	target: document.body,
	props: {
		style: style,
		config: config,
    imageFolderName: imageFolderName,
	}
});

export default app;