<script>
  import { createEventDispatcher, afterUpdate } from "svelte";

	import QuickReplies from './QuickReplies.svelte';
  import ConversationContainer from "./ConversationContainer.svelte";
  import StaggeredContent from "../../lib/StaggeredContent.js";
	import BrandResponse from "./BrandResponse.svelte";
  import LoadingImage from './LoadingImage.svelte';
  
  export let width;
  export let content = [];
  export let style;
  export let rtl = false;
  export let hasInput = true;
  export let ySpaceBetweenCards = "2px";
  export let chatTimingMS = 250;
  export let analytics = null;
  export let customPayloadRenderer = null;
  export let centerCardButtons = true;
  export let conversationHeight;
  export let darkMode = false;
  export let imagesHaveBorders = false;
  export let hasEngaged = false;

  let dispatch = createEventDispatcher();
  let loadMode = true;
  let hasMeasured = false;
  let staticContentHeight = 0;
  let imageHeight = null;
  let imageHasLoaded = false;

  afterUpdate(() => {
    if(!hasMeasured){
      hasMeasured = true;
      imageHeight = parseInt(conversationHeight) - parseInt(staticContentHeight) - 8 // 8 is the padding bottom of the simulated conversation
    }
  });


  let staggeredContent = new StaggeredContent(
    [],
    chatTimingMS,
    updateCallback,
    true
  );
  let simulatedContent = [];
  staggeredContent.add(content);
  
  function handlePostback(event) {
    dispatch("postback", event.detail);
  }
  function handleOpenURL(event) {
    dispatch("openUrl", event.detail);
  }

  async function updateCallback(items, turnOffLoadMode) {
    loadMode = !turnOffLoadMode;
    simulatedContent = items;
  }

</script>
<div class='simulated-conversation pt-1'
  bind:clientHeight={staticContentHeight}
  style={`
    padding-bottom: ${(!hasInput) ? '8px' : '0'}
  `}
>
{#if hasMeasured}
  {#each content as item}
    {#if item.type === 'image'}
      <div class="px-[11px] pb-1 max-w-[90%]">
          <LoadingImage
            style={style}
            makeHotSpot={true}
            altTag="hero"
            mediaHeight={item.imageHeight}
            src={item.src}
            imagesHaveBorders={imagesHaveBorders}
            hasEngaged={hasEngaged}
            shouldLoop={item.shouldLoop}
            on:imageLoaded={(event) => 
              imageHasLoaded = true
            }
            on:imageClick={(event) => 
              dispatch("imageClick")
            }
          />
      </div>
    {/if}
  {/each}
    {#if imageHasLoaded}
      <ConversationContainer
        styles={style}
        darkMode={darkMode}
        width={width}
        isLoading={loadMode}
        content={simulatedContent}
        rtl={rtl}
        centerCardButtons={centerCardButtons}
        customPayloadRenderer={customPayloadRenderer}
        ySpaceBetweenCards={ySpaceBetweenCards}
        analytics={analytics}
        chatTimingMS={chatTimingMS}
        hasEngaged={hasEngaged}
        shouldAnimate={true}
        on:postback={handlePostback}
        on:openUrl={handleOpenURL}
      />
    {/if}
  {:else}
    <div class="invisible">
      {#each content as item}
        {#if item.type === 'brandResponse'}
          <BrandResponse
            styles={style}
            text={item.text}
            width={item.width}
            rtl={rtl}
            centerCardButtons={centerCardButtons}
            ySpaceBetweenCards={ySpaceBetweenCards}
          />
        {/if}

        {#if item.type === 'quickReplies'}
          <QuickReplies
            style={style}
            item={item}
            rtl={rtl}
            centerCardButtons={centerCardButtons}
            analytics={analytics}
            compactMode={item.compactMode}
            animatedBuild={false}
            animationSpeed={chatTimingMS}
          />
        {/if}
      {/each}
    </div>
  {/if}
</div>
