<script>
	import { Svroller } from 'svrollbar';
  import ConversationContainer from "./ConversationContainer.svelte";
  import { tick, createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let content;
  export let height = '100%';
  export let styles;
  export let isLoading = null;
  export let rtl = false;
  export let carouselArrowButtonY = null;
  export let ySpaceBetweenCards = null;
  export let analytics = null;
  export let customPayloadRenderer = null;
  export let trackingID = null;
  export let centerCardButtons = false
  export let darkMode = false;
  export let chatTimingMS = 0;
  export let cardWidth;

  let shouldScroll = false;
  let scrollContentHeight = 0;
  let fullContentHeight = 0;
  let lastScrollPosition = null;
  
  $:
    if (content) {
      scrollToBottom('scroller')
    }

  function handlePostback(event) {
    dispatch("postback", event.detail);
  }

  function handleOpenURL(event) {
    dispatch("openUrl", event.detail);
  }

  function handleInstruction(event) {
    dispatch("instruction", event.detail);
  }
  const scrollToBottom = async (event = null) => {
    const element = document.getElementsByClassName('svlr-viewport')[0] 
    if(element) element.scrollTop = 999999 
    if(element && event.detail && event.detail.height && (event.detail.height !== lastScrollPosition)){ 
      setTimeout(() => { 
        element.scrollTop = 999999 
      }, 250)
      lastScrollPosition = fullContentHeight;
    }
    shouldScroll = scrollContentHeight >= parseInt(height)
}

</script>
<div class="content-wrapper w-[100%] h-full relative">
  <Svroller width={"100%"} height={height} alwaysVisible={false}>
    <div class={`overflow-hidden content-wrapper w-full ${(!shouldScroll) ? 'absolute bottom-0' : ''}`} 
    bind:clientHeight={scrollContentHeight}>
        <ConversationContainer
          styles={styles}
          darkMode={darkMode}
          width="100%"
          content={content}
          on:instruction={handleInstruction}
          isLoading={isLoading}
          carouselArrowButtonY={carouselArrowButtonY}
          rtl={rtl}
          chatTimingMS={chatTimingMS}
          ySpaceBetweenCards={ySpaceBetweenCards}
          analytics={analytics}
          customPayloadRenderer={customPayloadRenderer}
          trackingID={trackingID}
          centerCardButtons={centerCardButtons}
          cardWidth={cardWidth}
          on:postback={handlePostback}
          on:openUrl={handleOpenURL}
          on:change={(event) => dispatch('change', event.detail)}
          on:resize={scrollToBottom}
        />
    </div>
  </Svroller>
</div>
<style>
  .viewport{
    overflow-y: scroll;
    height: 200px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .viewport::-webkit-scrollbar {
    /* hide scrollbar */
    display: none;
  }
</style>
